import React, { useState } from 'react'
import Hosgeldiniz from '../Bilesenler/Hosgeldiniz'
import BizeUlasin from '../Bilesenler/BizeUlasin'

const GirisSayfasi = () =>{
    const [hosgeldinizGoster, setHosgeldinizGoster] = useState(true)
    setInterval( () => {
        // console.log("buraya geliyor mu")
        setHosgeldinizGoster(false)
    }, 3000)
    return (
        <div>
        <BizeUlasin/>
            {hosgeldinizGoster &&
            <Hosgeldiniz/>
            }
        </div>
    )
}

export default GirisSayfasi
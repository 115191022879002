import React from 'react'
import { useTranslation } from 'react-i18next'
import Etiketler from '../Bilesenler/Etiketler'

/**
Tercüme Hizmetleri

Şirketimiz dünyanın bütün dillerinde yeminli, noter onaylı tercüme, lokalizasyon, internasyonalizasyon hizmetleri vermektedir.

WHATSAPP: +90 533 520 32 74
Siz müşterilerimizin ihtiyaçlarına göre en iyi çözümleri üretmek, projelerinizde birlikte çalışmak üzere lütfen 0 312 430 35 05 ya da 0850 803 0838 numaradan arayarak ya da bağlantı formunu kullanarak fiyat isteyiniz.

Translation Services

Our company provides certified, notarized translation, localization, internationalization services in any language of the world.

WHATSAPP: +90 530 147 20 80
Please call us at + 90 312 430 35 05 or +90 850 803 0838 or use contact form to enable us to create the best solutions for your requirements and work together on your projects.
 */

const Ceviri =() => {
    const { t } = useTranslation()
    const ceviriHizmetleri = {
            id: 1,
            baslik: t('genericStrings:translationServicesTitle'),
            tanim: t('genericStrings:translationServicesDescriptionForPage'),
            body: [
                t('genericStrings:translationServicesDescriptionForPage'), 
                t('genericStrings:translationServicesWhatsAppNumberForPage'), 
                t('genericStrings:translationServicesPleaseCallusForPage')
            ],
            // tags: t('genericStrings:translationServicesTranslationTags')
            tags: t('genericStrings:translationServicesTranslationTags', { returnObjects: true })
        }
    return (
        <div className='baglantilarCls'>
            <h1 aria-description={ceviriHizmetleri.tanim}>{ceviriHizmetleri.baslik}</h1>
            <p aria-description={ceviriHizmetleri.body[0]}>{ceviriHizmetleri.body[0]}</p>
            <p aria-description={ceviriHizmetleri.body[1]}>{ceviriHizmetleri.body[1]}</p>
            <p aria-description={ceviriHizmetleri.body[2]}>{ceviriHizmetleri.body[2]}</p>
            <Etiketler gelenTaglar={ceviriHizmetleri.tags} eklenecekTanim={ceviriHizmetleri.tanim} />
        </div>
    )
}

export default Ceviri
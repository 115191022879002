import React from 'react'
import SirketKunyesi from './SirketKunyesi'

const BizeUlasin = () =>{
    return (
        <div>
            <SirketKunyesi/>
        </div>
    )
}

export default BizeUlasin
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Etiketler from '../Bilesenler/Etiketler'

import SirketKunyesi from '../Bilesenler/SirketKunyesi'

import './Hakkimizda.css'
/**
Şirket Profili

ATB Yeminli Tercüme yolculuğuna 1998 yılında bir şahıs firması olarak başladı. Şahıs firması olarak yaklaşık 6 yıl hizmet verdikten sonra, gerek müşteri yelpazesinin genişlemesi gerekse şirketleşmenin getirdiği avantajlar nedeniyle, 2004 yılında yasal statüsünü Limited Şirket'e dönüştürdü.
O zamandan bu yana hizmetlerini bu statü altında devam ettirmektedir.
Büromuz niş bir pazarı olan butik bir bürodur. Müşterilerimiz kendilerine sağladığımız imkanlar ve onların ihtiyaçlarını bilen, onların üretim süreçlerine dahil olmaya çalışan bir takım oyuncusu olduğumuz için bizleri tercih etmektedir.
Vizyonumuz
Vizyonumuz şu ana kadar ülkemizde ihmal edilmiş olan tercüme mesleğinin hak ettiği noktaya ulaşması ve gerek kalite, gerekse kapsam açısından dünya standartlarının üzerine çıkması ve entellektüel kapasitesinin, bir yazarlık faaliyeti olduğunun tanınmasıdır.
Ayrıca tercüme hizmetinin/mesleğinin gerek üretim gerekse hizmet sektöründeki sayın müşterilerimiz tarafından üretim süreçlerine dahil edilmesini ve üretimin bir aşaması olarak görülmesi de vizyonumuzun bir parçasını oluşturmaktadır.
Misyonumuz
Bir tercüme bürosu olarak misyonumuz, sayın müşterilerimizi anlayıp aslında temel olarak bir iletişim sorunu olan tercüme ihtiyaçlarına en uygun çözümleri bularak onların üretim/iş süreçleri içerisine dahil olmak ve onların takımlarının bir oyuncusu gibi davranmak ve entegre olmaktır.
ATB Yeminli Tercüme Yazılım
İnşaat Turizm İç ve Dış Ticaret Ltd. Şti.

WHATSAPP: +90 533 520 32 74
Tel: 0850 803 08 38 - 0312 430 35 05
Email : Bize Ulaşın

Company Profile
ATB Certified Translation set out its journey in 1998, as a proprietary company. After serving for about 6 years as a proprietary company, it transformed its legal status into a Limited Liability Company thanks to its larger cientelle and advantages of being a limited liability company.
Since then, it continues providing services in this legal status.
Our establishment is a boutique translation office with a niche market. Our clients prefer us thanks to possibilities we provide to them and since we are team players who are aware of our client's needs and integrating ourselves into their production process.
Our Vision
Our vision includes bringing the translation profession which has been neglected in our country, to the level it deserves to be, ensuring the profession to exceed world standards in terms of quality and scope and its intellectual capacity and its nature of being an authoring activity be recognized.
Moreover ensuring the translation services /profession be included/involved and integrated in the manufacturing process and seen as a part of manufacturing process by our clients is also a part of our vision.
Our Mission
Our mission, as a translation office, is to understand the requirements of our clients, to find the best solution for their translation needs, which are, in essence, a problem of communication, and get involved in production / job processes and act as a team player and integrate into their system.
ATB Yeminli Tercüme Yazılım
İnşaat Turizm İç ve Dış Ticaret Ltd. Şti. (Co. Ltd.)
WHATSAPP: +90 530 147 20 80
Phone: +90 850 803 08 38 - +90 312 430 35 05
Email : Contact Us

© 2011-2019 ATB Yeminli Tercüme Ltd. Şti.
Ataç-2 Sk No 52/6 Kızılay Ankara Türkiye
WHATSAPP: +90 533 520 32 74
Tel: 0850 803 08 38 - 0312 430 35 05

© 2011-2019 ATB Certified Translation Co, Ltd.
Atac-2 Sk No 52/6 Kizilay Ankara Turkey
WHATSAPP: +90 530 147 20 80
Tel: +90 850 803 08 38 - +90 312 430 35 05
 */

export const snippet = {
    "@context": "https://schema.org",
    "@type": ["Translation Office", "Tercüme Bürosu", "Çeviri Bürosu", "Sözlü Çeviri", "Yazılı Çeviri", "Sözlü Tercüme", "Yazılı Tercüme"],
    "image": [
        "https://www.atbtercume.com/img/logo.png"
    ],
    "name": "ATB Yeminli Tercüme Bürosu Ltd. Şti. (Co. Ltd.)",
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "Kültür Mahallesi Ataç-2 Sk. No 52/6",
        "addressLocality": "Kızılay",
        "addressRegion": "Ankara",
        "postalCode": "06420",
        "addressCountry": "TR"
    },
    "geo": {
        "@type": "GeoCoordinates",
        "latitude": 39.92137916670711,
        "longitude": 32.860246352807394
    },
    "duns": "502752206",
    "url": ["https://www.ankarayeminlitercume.com", "https://www.atbtercume.com"],
    "priceRange": "TL",
    "telephone": "+903124303505",
    "openingHoursSpecification": [
        {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ],
        "opens": "09:00",
        "closes": "17:59"
        }
    ],
    "department": [
        {
        "@type": "LocalBusiness",
        "image": [        
        "https://www.atbtercume.com/img/logo.png"
    ],
        "name": "ATB Yeminli Tercüme Bürosu Ltd. Şti. (Co. Ltd.)",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Kültür Mahallesi Ataç-2 Sk. No 52/6",
        "addressLocality": "Kızılay",
        "addressRegion": "Ankara",
        "postalCode": "06420",
        "addressCountry": "TR"
        },
        "priceRange": "TL",
        "telephone": "+903124303505",
        "openingHoursSpecification": [
            {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
            ],
            "opens": "09:00",
            "closes": "17:59"
            }
        ]
        }
    ]
}

const Hakkimizda = () =>{
    const { t } = useTranslation()
    const companyProfileListesi = {   
            id: 1,
            baslik: t('genericStrings:companyProfileHeading'),
            tanim: t('genericStrings:companyProfileHeading'),
            body: t('genericStrings:companyProfileBody:paragraphs', { returnObjects: true }),
            tags: t('genericStrings:companyProfileTags', { returnObjects: true })
        }
    const companyMissionAndVisionListesi = [
            {   
                id: 1,
                baslik: t('genericStrings:companyMissionHeading'),
                tanim: t('genericStrings:companyMissionHeading'),
                body: t('genericStrings:companyMissionBody:paragraphs', { returnObjects: true })
            },
            {   
                id: 2,
                baslik: t('genericStrings:companyVisionHeading'),
                tanim: t('genericStrings:companyVisionHeading'),
                body: t('genericStrings:companyVisionBody:paragraphs', { returnObjects: true })
            },
        ]
const companyProfileParagraphs = 
    companyProfileListesi
    .body
    .map((paragraf, i) => {
        return (
            <p key={i} 
            aria-description={paragraf}
            className='profileParagraphCls'
            >
            {paragraf}
            </p>
        )
    })
const companyMissionParagraphs = 
    companyMissionAndVisionListesi[0]
    .body
    .map((paragraf, i) => {
        return (
            <p key={i} 
            aria-description={paragraf}
            className='profileParagraphCls'
            >
            {paragraf}
            </p>
        )
    })
const companyVisionParagraphs = 
    companyMissionAndVisionListesi[1]
    .body
    .map((paragraf, i) => {
        return (
            <p 
            key={i} 
            aria-description={paragraf}
            className='profileParagraphCls'
            >
            {paragraf}
            </p>
        )
    })

    return (
        <div 
        className='baglantilarCls'
        >
            <h1 
            aria-description={companyProfileListesi.tanim}
            className='profileHeaderOneCls'
            >{companyProfileListesi.tanim}</h1>
            {companyProfileParagraphs}
            <h1 
            aria-description={companyMissionAndVisionListesi[0].tanim}
            className='profileHeaderOneCls'
            >{companyMissionAndVisionListesi[0].tanim}</h1>
            {companyMissionParagraphs}
            <h1 
            aria-description={companyMissionAndVisionListesi[1].tanim}
            className='profileHeaderOneCls'
            >{companyMissionAndVisionListesi[1].tanim}</h1>
            {companyVisionParagraphs}

            <SirketKunyesi/>
            <Etiketler gelenTaglar={companyProfileListesi.tags} eklenecekTanim={companyProfileListesi.tanim} />
        </div>
    )
}

export default Hakkimizda
import React from 'react'
import { motion } from "framer-motion";

import './CloseButton.css'
import { rotateVariant } from './NavBar';

const CloseButton =( { sagaDayali, setTelefonDiyalogu }) =>{
    return (
        <motion.div
        className= {sagaDayali ? 'sagaDayaliKapatmaDugmesiCls' : 'solaDayaliKapatmaDugmesiCls'}
        onClick={() => {setTelefonDiyalogu(false)}}
        variants={rotateVariant}
        initial="initial"
        animate="rotate"
        >
        X
        </motion.div>
    )
}

export default CloseButton
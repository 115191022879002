const hizmetIscisiGelistirme = () => {
    console.log("hizmetIscisiGelistirme")
    if ('serviceWorker' in navigator) {
        console.log("hizmetIscisiGelistirme 2")
        const serviceIscisiAcik = `${process.env.PUBLIC_URL}/hizmetIscisi.js`
        // const bildirimHizmetIscisiAcik = `${process.env.PUBLIC_URL}/js/bildirimHizmetIscisi.js`
        console.log("serviceIscisiAcik: ", serviceIscisiAcik)
        // console.log("bildirimHizmetIscisiAcik: ", bildirimHizmetIscisiAcik)
        navigator
        .serviceWorker
        // .register(`${process.env.PUBLIC_URL}/js/hizmetIscisi.js`, {scope: '/'})
        .register(serviceIscisiAcik)
        .then(registration => {
            console.log("hizmetIscisiGelistirme 3")
            // console.log(registration)
        })
        .catch(err => {
            console.log("catch err: ", err)
        })
        /*navigator
        .serviceWorker
        .register(bildirimHizmetIscisiAcik)
        .then(response => {
            console.log("bildirimHizmetIscisiAcik 3")
            // console.log(response)
        })
        .catch(err => {
            console.log(err)
        })*/
    }
}

export default hizmetIscisiGelistirme;
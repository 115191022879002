import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import NavBar from '../Bilesenler/NavBar'
import LoadingSpinner from '../Bilesenler/LoadingSpinner'
import Footer from '../Bilesenler/Footer';
import EkmekKirintilari from '../Bilesenler/EkmekKirintilari';

const KokYerlesim = () => {
    const {ready } = useTranslation();
    return (
        <div className='kokYerlesimCls'>
            <NavBar/>
            {!ready && <LoadingSpinner/>}
            <div className='anaBolum'>
            <EkmekKirintilari />
                <Outlet/>
            </div>
            <Footer/>
        </div>
    )
}
export default KokYerlesim
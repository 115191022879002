import React, {useEffect, useRef, useState} from 'react'
import { motion, useAnimate } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FaFacebook, FaTwitter, FaThumbsUp} from 'react-icons/fa'

import './DropUpMenu.css'
import CloseButton from './CloseButton'

const DropUpMenu = () => {
    const { t } = useTranslation()
    const [sosyalMedyaKapsami, animate] = useAnimate();
    const [sagAltMenuAcik, setSagAltMenuAcik] = useState(false)
    const yukariAcilirMenuKalemleriRef = useRef()
    // const [animasyonHazir, setAnimasyonHazir] = useState(false)

    const divMetniniDegistir = async (str) => {
        // console.log("str: ", str)
        sosyalMedyaKapsami.current.innerHTML = str
    }

    let sosyalMedyaListesi = t('sosyalMedya:liste', {returnObjects: true})
    // console.log("sosyalMedya: ", sosyalMedyaListesi)
    useEffect(() => {
        sosyalMedyaListesi = t('sosyalMedya:liste', {returnObjects: true})
        // console.log("sosyalMedya: ", sosyalMedyaListesi)
        //Object.keys(sosyalMedyaListesi).forEach((eleman, dizin) =>{
            // sosyalMedyaKapsami.current.innerHTML = t('footerStrings:paylasS')
            // return console.log('eleman: ', eleman, "dizin: ", dizin)
        // })
        // setAnimasyonHazir(true)
    }, [document.documentElement.lang])
    // t('SosyalMedya:')

    useEffect( () => {
            const animateButtons = async () =>{   
                // console.log("animateButtons sosyalMedyaKapsami: ")
                // if(sosyalMedyaKapsami) console.log("sosyalMedyaKapsami: ", sosyalMedyaKapsami)
                // let gecici = t('footerStrings:liste')
                // console.log("gecici: ", Object.keys(gecici))
                // if(sagAltMenuAcik){
                //     return
                // }
                await divMetniniDegistir(t('footerStrings:paylasS'))
                await animate(sosyalMedyaKapsami.current, { y: ["110%", "0%"]}, {duration: 1})
                await divMetniniDegistir(t('footerStrings:begenS'))
                await animate(sosyalMedyaKapsami.current, { y: ["110%", "0%"]}, {duration: 1})
                await divMetniniDegistir(t('footerStrings:takipEtS'))
                await animate(sosyalMedyaKapsami.current, { y: ["110%", "0%"]}, {duration: 1})
                animateButtons()
            }
            // eski biraz çalışan hali aşağıda
            //     await animate(
            // [
            //     [sosyalMedyaKapsami.current, { y: "110%"}, divMetniniDegistir(t('footerStrings:paylasS'))],
            //     [sosyalMedyaKapsami.current, { y: ["110%", "0%"]}],
            //     [sosyalMedyaKapsami.current, { y: "110%"}, divMetniniDegistir(t('footerStrings:begenS')), {delay:2}],
            //     [sosyalMedyaKapsami.current, { y: ["110%", "0%"]}, {duration: 2}],
            //     [sosyalMedyaKapsami.current, { y: "110%"}, divMetniniDegistir(t('footerStrings:takipEtS')), {delay: 2}],
            //     [sosyalMedyaKapsami.current, { y: ["110%", "0%"]}, {duration: 2}],
            //     // [sosyalMedyaKapsami.current, { y: "110%"}, {content: divMetniniDegistir(t('footerStrings:begenS'))}, {duration: 1}],
            //     // [sosyalMedyaKapsami.current, { y: ["110%", "0%"]}, {transition: {staggerChildren: 0.5}}, {duration: 1, delay:1}],
            //     // [sosyalMedyaKapsami.current, { y: "110%"}, {content: divMetniniDegistir(t('footerStrings:takipEtS'))}, {duration: 1}],
            //     // [sosyalMedyaKapsami.current, { y: ["110%", "0%"]}, {transition: {staggerChildren: 0.5}}, {duration: 1, delay:1}],
            //     // [sosyalMedyaKapsami.current, { y: [20, 0]}, {innerHTML: t('sosyalMedya:liste:ATB').baslik}, {duration: 1, repeat: Infinity}],
            //     // [sosyalMedyaKapsami.current, { y: [20, 0]}, {duration: 1, repeat: Infinity}],
            //     // [sosyalMedyaKapsami.current, { y: [20, 0]}, {duration: 1, repeat: Infinity}],
            //     // [sosyalMedyaKapsami.current, { y: [20, 0]}, {duration: 1, repeat: Infinity}],
            //     // [sosyalMedyaKapsami.current, { y: [20, 0]}, {duration: 1, repeat: Infinity}],
            //     // [sosyalMedyaKapsami.current, { y: [20, 0]}, {duration: 1, repeat: Infinity}],
            //     // [sosyalMedyaKapsami.current, { opacity: 0.1}, {duration: 2, repeat: Infinity}],
            //     // [sosyalMedyaKapsami.current, { color: 'red'}, {duration: 5, repeat: Infinity}],

            //     /**
            //      * ["#faceBookFollow", 
            //         {x:["-100vw", "0vw", "0vw", "0vw", "0vw", "0vw", "-100vw"], 
            //         scale:[1, scaleFactor, scaleFactor, scaleFactor, scaleFactor, scaleFactor, 1], 
            //         originX:0}, { duration: 5, delay: stagger(0.05) }],
            //      */
            // ],
            //     {repeat:Infinity})
            // }
                animateButtons();
    }, [document.documentElement.lang])

    const ikonGetir = (str) => {
        if (str === "facebooksayfasi") 
        {
            return (<FaFacebook
                color='#00FFFF'
            />)
        }
        else if (str === "twitter") 
        {
            return (<FaTwitter
                color='#00FFFF'
            />)
        }
    }

    console.log("sagAltMenuAcik : yukariAcilirMenuKalemleriniGoster öncesi", sagAltMenuAcik)
    const yukariAcilirMenuKalemleriniGoster = () => {
        // setSagAltMenuAcik(true)
        setSagAltMenuAcik(!sagAltMenuAcik)
        console.log("sagAltMenuAcik içeride: ", sagAltMenuAcik)
    }

    return (
        <div 
        className='sagAltKoseCls'
        onClick={yukariAcilirMenuKalemleriniGoster}
        >
            <div className='greenBackgroundCls'>
                <motion.div 
                ref={sosyalMedyaKapsami} 
                id='buttonAnimationID' 
                className='animationCls'
                // className={sagAltMenuAcik ? 'animationCls invisibleCls' : 'animationCls'}
                >
                </motion.div>
                <div 
                ref={yukariAcilirMenuKalemleriRef} 
                className={sagAltMenuAcik ? 'yukariAcilirMenuKalemleri greenBackgroundCls' : 'yukariAcilirMenuKalemleri greenBackgroundCls invisibleCls'}
                >
                <CloseButton sagaDayali={false} setTelefonDiyalogu={setSagAltMenuAcik}/>
                { sosyalMedyaListesi.map((eleman, dizin) => {
                    return (
                        <Link
                        to={eleman.begenmeBaglantisi}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='yukariAcilirMenuKalemi'
                        key={dizin}
                        >
                        {ikonGetir(eleman.turu)}<span> </span>
                        {eleman.baslik}
                        </Link>
                    )
                })}

                {/*gelenTaglar.map(tag => {
                return (
                    <Link
                    key={tag}
                    to='#'
                    aria-description={tag}
                    >
                    {etiketOlustur(tag)}
                    </Link>
                )
            })*/}
                </div>
            </div>
        </div>
    )
}

export default DropUpMenu
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'


import './TelefonVeWhatsApp.css'
import { FaPhone, FaWhatsapp } from 'react-icons/fa'
import CloseButton from './CloseButton'

const TelefonVeWhatsApp =( { setTelefonDiyalogu }) =>{
    const { t } = useTranslation()
    return (
        <div className='telefonVeWhatsAppCls'>
            <CloseButton sagaDayali={true}
            setTelefonDiyalogu={setTelefonDiyalogu}
            />
            <Link 
            to={"tel:"+t('genericStrings:companyContactInfo:whatsAppNo')}
            target='_blank'
            rel='noopener noreferrer'
            >
            <FaPhone
                color='#FFFFFF'
                size={25}
            /> 
            {t('genericStrings:companyContactInfo:biziTelefonlaAra')}
            </Link>
            <Link 
            to={'https://wa.me/'+t('genericStrings:companyContactInfo:whatsAppNo2')+'?text=www.atbtercume.com'}
            target='_blank'
            rel='noopener noreferrer'
            >
            <FaWhatsapp
                color='#00FFFF'
                size={25}
            />
            {t('genericStrings:companyContactInfo:biziWhatsApplaAra')}
            </Link>
        </div>
    )
}

export default TelefonVeWhatsApp
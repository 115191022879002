import React from 'react'
import { useTranslation } from 'react-i18next'

import Etiketler from '../Bilesenler/Etiketler'

/**
Web Site Design

Our company designs both static and dynamic web sites in multiple languages, our clients desire.

WHATSAPP: +90 530 147 20 80
Please call us at + 90 312 430 35 05 or +90 850 803 0838 or use contact form to learn about our prices and other services.

Web Sitesi Hazırlama

Şirketimiz gerek statik gerekse dinamik web sitelerini talep üzerine birden fazla dilde hazırlamaktadır.

WHATSAPP: +90 533 520 32 74
Bu konudaki fiyatlarımızı ve hizmetlerimizi öğrenmek üzere lütfen 0 312 430 35 05 ya da 0850 803 0838 numaradan arayarak ya da bağlantı formunu kullanarak fiyat isteyiniz.

 */

const WebGelistirme =() =>{
    const { t } = useTranslation()
    const webGelistirmeHizmetleri = {
            id: 1,
            baslik: t('genericStrings:webDevelopmentServicesTitle'),
            tanim: t('genericStrings:webDevelopmentServicesDescriptionForPage'),
            body: t('genericStrings:webDevelopmentServicesPleaseCallusForPage'),
            tags: t('genericStrings:webDevelopmentServicesTags', { returnObjects: true })
        }
    return (
        <div className='baglantilarCls'>
            <h1 aria-description={webGelistirmeHizmetleri.tanim}>{webGelistirmeHizmetleri.baslik}</h1>
            <p aria-description={webGelistirmeHizmetleri.tanim}>{webGelistirmeHizmetleri.tanim}</p>
            <p aria-description={webGelistirmeHizmetleri.body}>{webGelistirmeHizmetleri.body}</p>
            <Etiketler gelenTaglar={webGelistirmeHizmetleri.tags} eklenecekTanim={webGelistirmeHizmetleri.tanim} />
        </div>
    )
}

export default WebGelistirme
import React from 'react'

import './Baglantilar.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Etiketler from '../Bilesenler/Etiketler'

const Baglantilar = () => {
    const { t } = useTranslation()
    const baglantiList = [
        {
            id: 1,
            baslik: t('regularMenus:TOMSMenu'),
            tanim: t('genericStrings:TOMSDescription'),
            baglanti: "https://toms.atb-soft.com",
        },
        {
            id: 2,
            baslik: t('regularMenus:cevirdicBizAndroidMenu'),
            tanim: t('genericStrings:TDSDictionaryAndroidDescription'),
            baglanti: "https://play.google.com/store/apps/details?id=my.translation.dictionary"
        },
        {
            id: 3,
            baslik: t('regularMenus:cevirdicBiziOsMenu'),
            tanim: t('genericStrings:TDSDictionaryiOsDescription'),
            baglanti: "https://apps.apple.com/app/cevirdic-biz/id1642503577"
        },
        {
            id: 4,
            baslik: t('regularMenus:hangAWordAndroidMenu'),
            tanim: t('genericStrings:hangAWordAndroidDescription'),
            baglanti: "https://play.google.com/store/apps/details?id=my.translation.hangaword"
        },
        {
            id: 5,
            baslik: t('regularMenus:hangAWordiOsMenu'),
            tanim: t('genericStrings:hangAWordiOsDescription'),
            baglanti: "https://apps.apple.com/us/app/hangaword/id6475181526"
        },
    ]
    
    return (
        <section className='baglantilarCls'>
            {baglantiList.map(baglanti => {
                return (
                    <div 
                    key={baglanti.id}
                    >
                    <p className="linkBasligiCls">{baglanti.baslik}</p>
                    <p className="linkTanimiCls">{baglanti.tanim}</p>
                    <Link to={baglanti.baglanti} 
                    target='_blank' 
                    className="linkBaglantisiCls">{baglanti.baslik}</Link>
                    </div>
                )
            })}
            <Etiketler eklenecekTanim="Software Services" gelenTaglar={t('genericStrings:softwareServicesTags', {returnObjects: true})}/>
        </section>
    )
}

/*export const baglantilariYukle = async () => {
    
}*/

export default Baglantilar
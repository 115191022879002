import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CookiesProvider } from "react-cookie";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  // useLocation
} from "react-router-dom"
import Helmet from "react-helmet"

import KokYerlesim from "./Yerlesimler/KokYerlesim";

import './Stylelar/Main.css';
import './i18n'
import { GenelAyarlarSaglayici } from "./Baglamlar/GenelAyarlarSaglayici";
import Bulunamadi from "./Sayfalar/Bulunamadi";
import Baglantilar from "./Sayfalar/Baglantilar";
import Ceviri from "./Sayfalar/Ceviri";
import WebGelistirme from "./Sayfalar/WebGelistirme";
import OzelYazilimGelistirme from "./Sayfalar/OzelYazilimGelistirme";
import Haberler from "./Sayfalar/Haberler";
import Hakkimizda from "./Sayfalar/Hakkimizda";
import BizeUlasin from "./Bilesenler/BizeUlasin";
import GirisSayfasi from "./Sayfalar/GirisSayfasi";
import { initializeTagManager } from "./gtm";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route 
    path="/" 
    element={<KokYerlesim/>}
    >
    <Route index element={<GirisSayfasi />}/>
      <Route 
      path="aboutus" 
      element={<Hakkimizda />}
      />
      <Route 
      path="links" 
      element={<Baglantilar />}
      // loader={baglantilariYukle} //burası bağlantılar için
      // handle={{
      //   kirinti: (kirinti) => 
      // }} burası kırıntıları yüklemek için
      />
      <Route 
      path="news" 
      element={<Haberler />}
      />
      <Route 
      path="/customsoftware" 
      element={<OzelYazilimGelistirme />} 
      />
      <Route 
      path="/webdevelopment" 
      element={<WebGelistirme />} 
      />
      <Route 
      path="/translationservices" 
      element={<Ceviri />} 
      />
      <Route 
      path="/contactus" 
      element={<BizeUlasin />} 
      />
      {/* <Route 
      path= {(path) => {
        if (path.includes("TOMS")) {
          return true
        }
      } }
      path= {`/${tomsText}`}
      element={<RedirectToToms />} 
      /> */}
      <Route 
      path="*" 
      element={<Bulunamadi />} 
      />
    </Route>
  )
);

/*function RedirectToToms() {
  window.location.replace("https://toms.atb-soft.com/products")
}*/

function App() {
  // const currentLocation = useLocation()
  const { t, ready } = useTranslation();
  const documentTitle = `ATB Yeminli Tercüme - ${t('genericStrings:web_site_name')}`;
  const favIcon = '/img/favicon.ico'
  // const metaTags = t('genericStrings:genericogMetaTagsS')
  // const metaElement = new HTMLElement()

  useEffect( () => {
    // metaElement.innerText = metaTags
    // console.log("metaElement: ", metaElement.innerHTML)
    // document.head.append(metaElement)
    // document.title = documentTitle;
    // const favIconLink = document.querySelector('link[rel="icon"]');
    // favIconLink.setAttribute("href", favIcon)
    // const headElement = document.head
    // let elementToBeAdded = HTMLHeadElement
    // elementToBeAdded.innerHtml = metaTags
    // const headElement = document.getElementsByTagName('head').head
    // console.log('lang: ', document.documentElement.lang)
    // console.log('metaTags: ', metaTags)
    // headElement.append(elementToBeAdded)
    // console.log('headElement: ', headElement)
    // console.log("favIcon: ", favIcon, " favIconLink: ", favIconLink)
    /*if (currentLocation.includes("TOMS")) {
      window.location.replace("https://toms.atb-soft.com")
    }*/
    initializeTagManager()
  }, [])

  window.onerror = (message, source, lineno, colno, error) => {
    console.log(`message: ${message}, source: ${source}, lineno: ${lineno}, colno: ${colno}, error: ${error}`)
    return true
  }

  !ready && console.log('loading translations')
  return (
      <CookiesProvider defaultSetOptions={{path:'/'}}>
        <Helmet>
          <title>{documentTitle}</title>
          <meta name="description" content="ATB app" />
          <link rel="icon" href={favIcon}  />
          <meta property='og:url' content='https://www.atbtercume.com/'/>
          <meta property='og:type' content='website' />
          <meta property='og:title' content={t('genericStrings:genericogMetaTagsogTitleS')} />
          <meta property='og:description' content={t('genericStrings:genericogMetaTagsogDescriptionS')}  />
          <meta property='og:image' content='https://www.atbtercume.com/img/favicon.ico'/>"
        </Helmet>
        <GenelAyarlarSaglayici>
          <RouterProvider router={router}/>
        </GenelAyarlarSaglayici>
      </CookiesProvider>
  );
}

export default App;

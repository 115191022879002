import React from 'react'
import { Link } from 'react-router-dom'
import { etiketOlustur } from '../Utils/Utilities'

import './Etiketler.css'

const Etiketler = ({ gelenTaglar, eklenecekTanim }) => {
    // const donusturulmusEtiket = etiket
    // const donusturulmusEtiket = etiketOlustur(etiket)
    return (
        <div className='etiketlerCls'>
            {gelenTaglar.map(tag => {
                return (
                    <Link
                    key={tag}
                    to='#'
                    aria-description={tag}
                    >
                    {etiketOlustur(tag)}
                    </Link>
                )
            })}
        </div>
    )
}

export default Etiketler
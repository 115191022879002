import React from 'react'
import { useTranslation } from 'react-i18next'
import Etiketler from '../Bilesenler/Etiketler'

/**
 * 
Özel Bilgisayar Yazılımı

Firmamız siz müşterilerimizin özel olarak sipariş verdiği bilgisayar yazılımlarını (custom software) hazırlamaktadır.

WHATSAPP: +90 533 520 32 74
Bu konudaki fiyatlarımızı ve hizmetlerimizi öğrenmek üzere lütfen 0 312 430 35 05 ya da 0850 803 0838 numaradan arayarak ya da bağlantı formunu kullanarak fiyat isteyiniz.

Custom Software

Our company creates custom software asked by our clients.

WHATSAPP: +90 530 147 20 80
Please call at + 90 312 430 35 05 or +90 850 803 0838 or use contact form to learn about our prices and other services.
 */

const OzelYazilimGelistirme = () =>{
    const { t } = useTranslation()
    const ozelYazilimGelistirmeHizmetleri = {
            id: 1,
            baslik: t('genericStrings:customSoftwareDevelopmentServicesTitle'),
            tanim: t('genericStrings:customSoftwareDevelopmentServicesDescriptionForPage'),
            body: t('genericStrings:customSoftwareDevelopmentServicesPleaseCallusForPage'),
            tags: t('genericStrings:customSoftwareDevelopmentServicesTags', { returnObjects: true })
        }
    return (
        <div className='baglantilarCls'>
            <h1 aria-description={ozelYazilimGelistirmeHizmetleri.tanim}>{ozelYazilimGelistirmeHizmetleri.baslik}</h1>
            <p aria-description={ozelYazilimGelistirmeHizmetleri.tanim}>{ozelYazilimGelistirmeHizmetleri.tanim}</p>
            <p aria-description={ozelYazilimGelistirmeHizmetleri.body}>{ozelYazilimGelistirmeHizmetleri.body}</p>
            <Etiketler gelenTaglar={ozelYazilimGelistirmeHizmetleri.tags} eklenecekTanim={ozelYazilimGelistirmeHizmetleri.tanim} />
        </div>
    )
}

export default OzelYazilimGelistirme
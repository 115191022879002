export const etiketOlustur = (str) => {
    // console.log("str: ", str)
    let sozcukDizisi = str.split(' ').filter(char => {
        return char !== ""
    }); 
    let sonuc = "#"
    if (sozcukDizisi.length === 0) {
        return false;
    };

    sonuc = sonuc + sozcukDizisi.map(sozcuk => {
        let basHarfiBuyukSozcuk = sozcuk.charAt(0).toUpperCase()
        + sozcuk.slice(1);
        return basHarfiBuyukSozcuk
    }).join('')

    if(sonuc.length >100){
        throw Error('Uzunluk 100 karakterden daha fazla olamaz')
    }
    else {
        return sonuc
    }
};

export const gecerliYiliAl = () => {
    const gecerliYil = new Date(Date.now()).getYear()+1900
    // console.log("gecerliYil: ", gecerliYil)
    return gecerliYil
}
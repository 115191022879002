import React, { useState } from 'react'

import './Hosgeldiniz.css'

const Hosgeldiniz = ({ goster }) =>{
    const [gosterDialogu, setGosterDialogu] = useState(false)
    return (
            <div className='hosgeldinizArkaPlanCls'
            >
                <img 
                className='hosgeldinizIcericiCls'
                src="/img/TOMSCat-Welcome.gif" alt="<%= WelcomeLabel %>"/>
            </div>
    )
}

export default Hosgeldiniz
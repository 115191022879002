import React from 'react'
import { useTranslation } from 'react-i18next'
import { gecerliYiliAl } from '../Utils/Utilities'

import './Footer.css'
import DropUpMenu from './DropUpMenu'

const Footer =() => {
    const { t } = useTranslation()
    const gecerliYil = gecerliYiliAl()
    return (
        <footer>
        <div className="telifHakkiCls">
            {t('genericStrings:copyRightStartDate')} {gecerliYil} 
        </div>
        <DropUpMenu/>
        </footer>
    )
}

export default Footer
import React, { useEffect } from 'react'
import SirketKunyesi from '../Bilesenler/SirketKunyesi'

import './Bulunamadi.css'
import { useLocation } from 'react-router-dom';


const tomsText = /^.*?=TOMS/;
// const tomsText = '^(?=.*(TOMS))(?=.*(toms))(?=.*(Toms))(?=.*(tOms)).*$'
// const tomsText = '^(?=.*\bTOMS\b)(?=.*\btoms\b)(?=.*\bToms\b)(?=.*\btOms\b).*$'

/**
 * 
 * window.location.replace("https://toms.atb-soft.com/products")
 */

// if (window.location.includes("TOMS")){
//     window.location.replace("https://toms.atb-soft.com/products")
// }

const Bulunamadi = () => {
    const gecerliKonum = useLocation()
    // if (window.location.includes("TOMS")){
    //     window.location.replace("https://toms.atb-soft.com/products")
    // }
    // const konum = useLocation()

    useEffect(() => {
        // console.log("gecerliKonum.pathname: ", gecerliKonum.pathname)
        if (gecerliKonum.pathname.toUpperCase().includes("TOMS")) {
            window.location.replace("https://toms.atb-soft.com/products")
        }
    }, [])
    return (
        <div className='bulunamadiCls'>
            <SirketKunyesi/>
        </div>
    )
}

export default Bulunamadi
import React from 'react'

import './Bayrak.css'

const Bayrak =( { bayrak }) =>{
    return (
        <div className='inlineBlockCls'>
            <img className='bayrakImgCls'
            src= {bayrak}
            />
        </div>
    )
}

export default Bayrak
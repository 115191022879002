import React from 'react'

import './GomuluYoutubeVideosu.css'

const GomuluYoutubeVideosu = ({ videoUri, videoBasligi, videoGenisligi, videoYuksekligi }) => {
    return (
        <div className="videoResponsive">
            <iframe
            width={videoGenisligi}
            height={videoYuksekligi}
            src={`https://www.youtube.com/embed/${videoUri}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={videoBasligi}
            />
        </div>
    )
}

export default GomuluYoutubeVideosu
import { useState, useEffect } from "react";

const pencereOlculeriniAl = () => {
    const {
        innerWidth: genislik,
        innerHeight: yukseklik
    } = window
    return {
        genislik,
        yukseklik
    }
}

const useWindowDimensions = ()  =>{
    const [pencereOlculeri, setPencereOlculeri] = useState(pencereOlculeriniAl());

    useEffect(() => {
        const handleResize = () => {
            setPencereOlculeri(pencereOlculeriniAl());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return pencereOlculeri
};

export default useWindowDimensions;
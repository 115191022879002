import React, { useState, CSSProperties } from 'react'
import ClockLoader from 'react-spinners/ClockLoader'

import './LoadingSpinner.css'

const LoadingSpinner =() =>{
    let [yukluyor, setYukluyor] = useState(true);
    let [renk, setRenk] = useState('#FFFFFF');
    return (
        <div
        className='spinnerCls'
        >
            <ClockLoader 
            color={renk}
            loading={yukluyor}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
        </div>
    )
}

export default LoadingSpinner
import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { FaArrowRight } from "react-icons/fa6"
import { useTranslation } from 'react-i18next'
import { FaHome } from 'react-icons/fa'
import { RiWifiOffLine } from "react-icons/ri";
import { motion } from "framer-motion";

import './EkmekKirintilari.css'

const rotateVariant = {
    inital: {
        x: 5
    },
    rotate: {
        rotate: [0, 30, 0 -30],
        transition:{repeat: Infinity, duration:1}
    }
};

const EkmekKirintilari = () => {
    const konum = useLocation()
    // const uyanlar = useMatches()
    const { t } = useTranslation()

    let gecerliBaglanti = ''
    let gecerliBaglantiMetni = ''

    // const kirintilar = uyanlar
    //     .filter(kirinti => kirinti !== '')
    //     .map(kirinti => {
    //         gecerliBaglanti += `/${kirinti}`
    //         if(kirinti === 'links') {
    //             gecerliBaglantiMetni = t('regularMenus:linksMenu')
    //         }
    //         return (
    //             <div className="kirintiCls" key={kirinti}>
    //                 <Link to={gecerliBaglanti}><FaArrowRight/> {gecerliBaglantiMetni}</Link>
    //             </div>
    //         )
    //     })
    const kirintilar = konum.pathname.split('/')
        .filter(kirinti => kirinti !== '')
        .map(kirinti => {
            gecerliBaglanti += `/${kirinti}`
            if(kirinti === 'links') {
                gecerliBaglantiMetni = t('regularMenus:linksMenu')
            }
            else if(kirinti === 'translationservices') {
                gecerliBaglantiMetni = t('regularMenus:translationServicesMenu')
            }
            else if(kirinti === 'webdevelopment') {
                gecerliBaglantiMetni = t('regularMenus:websiteDevelopmentServicesMenu')
            }
            else if(kirinti === 'customsoftware') {
                gecerliBaglantiMetni = t('regularMenus:softwareDevelopmentServicesMenu')
            }
            else if(kirinti === 'contactus') {
                gecerliBaglantiMetni = t('regularMenus:contactUsMenu')
            }
            else if(kirinti === 'news') {
                gecerliBaglantiMetni = t('regularMenus:newsMenu')
            }
            else if(kirinti === 'aboutus') {
                gecerliBaglantiMetni = t('regularMenus:aboutUsMenu')
            }
            else if(kirinti === 'signin') {
                gecerliBaglantiMetni = t('regularMenus:loginMenu')
            }
            else if(kirinti === 'signup') {
                gecerliBaglantiMetni = t('regularMenus:registerMenu')
            }
            return (
                <div className="kirintiCls" key={kirinti}>
                    <Link to={gecerliBaglanti}><FaArrowRight/> {gecerliBaglantiMetni}</Link>
                </div>
            )
        })

        // console.log("kirintilar: ", kirintilar.toString())

    return (
        <div className='kirintilarCls'>
        {!navigator.onLine &&
        <motion.div
        size={50}
            variants={rotateVariant}
            initial="initial"
            animate="rotate"
            >
            <RiWifiOffLine
            size={50}
            color='#FF00FF'
            />
        </motion.div>
        }
        <Link to={"/"}
        className={kirintilar.length ===0 ? 'invisibleCls' : ''}
        >
        <FaHome size={25}/> 
        </Link>
        {kirintilar}</div>
    )
}

export default EkmekKirintilari
import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FaAngleDown, FaAngleLeft } from "react-icons/fa"
import { CiMenuBurger } from "react-icons/ci";

import useWindowDimensions from '../Kancalar/PencereBuyuklugunuKullan';

import './NavBar.css'
import Bayrak from './Bayrak';

export const throbVariant = {
    inital: {
        x: 5
    },
    throb: {
        scale:[1, 1.1],
        transition:{repeat: Infinity, duration:1}
    }
};

export const shakeVariant = {
    inital: {
        x: 0
    },
    shake: {
        translateX:[5, 0, 5],
        transition:{repeat: Infinity, duration:0.25}
    }
};

export const rotateVariant = {
    inital: {
        x: 0
    },
    rotate: {
        rotate:[30, 0, 30],
        transition:{repeat: Infinity, duration:1}
    }
};

const NavBar = () => {
    const [hizmetMenusuAc, setHizmetMenusuAc] = useState(false)
    const [yazilimHizmetMenusuAc, setYazilimHizmetMenusuAc] = useState(false)
    const [yazilimlarimizMenusuAc, setYazilimlarimizMenusuAc] = useState(false)
    const [loginMenusuAc, setLoginMenusuAc] = useState(false)
    const [dilMenusuAc, setDilMenusuAc] = useState(false)

    const [gosterilenMenu, setGosterilenMenu] = useState(null)
    const [logoGizlimi, setLogoGizlimi] = useState(false)

    const { t, i18n } = useTranslation();
    const { genislik } = useWindowDimensions();
    const AnimatedLink = motion(NavLink);

    const diliSimdiDegistir = (dil) => {
        if (navigator.onLine){
            document.documentElement.lang = dil
            // const dilOzelligi = document.documentElement.lang
            // dilOzelligi = dil
            i18n.changeLanguage(dil)
        }
    }
    const diliSimdiDegistirBayrak = () => {
        console.log('diliSimdiDegistirBayrak')
        if (navigator.onLine){
            if(i18n.language === 'tr'){
                i18n.changeLanguage('en')
            }
            else if(i18n.language === 'en'){
                i18n.changeLanguage('tr')
            }
            // const dilOzelligi = document.documentElement.lang
            // dilOzelligi = dil
        }
    }

    const menuleriSiraylaTekTekAcIsle = () => {
        console.log("bütün menüleri kapa")
        setHizmetMenusuAc(false)
        setYazilimlarimizMenusuAc(false)
        setYazilimHizmetMenusuAc(false)
        setLoginMenusuAc(false)
        setDilMenusuAc(false)
        console.log("hiç bir menü gösterilmiyorsa logoyu gizle")
        console.log("gosterilenMenu: ", gosterilenMenu)
        if(gosterilenMenu !== null){
            setLogoGizlimi(true)
            setGosterilenMenu('navAboutCls')
        }
        if(gosterilenMenu === null){
            setLogoGizlimi(true)
            setGosterilenMenu('navAboutCls')
        }
        else if(gosterilenMenu === 'navAboutCls'){
            setLogoGizlimi(true)
            setGosterilenMenu('navOurServicesCls')
        }
        else if(gosterilenMenu === 'navOurServicesCls'){
            setLogoGizlimi(true)
            setGosterilenMenu('navLinksCls')
        }
        else if(gosterilenMenu === 'navLinksCls'){
            setLogoGizlimi(true)
            setGosterilenMenu('navNewsCls')
        }
        else if(gosterilenMenu === 'navNewsCls'){
            setLogoGizlimi(true)
            setGosterilenMenu('navContactUsCls')
        }
        else if(gosterilenMenu === 'navContactUsCls'){
            setLogoGizlimi(true)
            setGosterilenMenu("navLoginCls")
        }
        else if(gosterilenMenu === 'navLoginCls'){
            setLogoGizlimi(true)
            setGosterilenMenu("navLanguageCls")
        }
        else if(gosterilenMenu === 'navLanguageCls'){
            setLogoGizlimi(false)
            setGosterilenMenu(null)
        }
        console.log("gosterilenMenu sonrası: ", gosterilenMenu)
    }

    const hizmetMenusuAcIsle = (e) => {
        e.stopPropagation()
        if(hizmetMenusuAc === true) {
            setYazilimlarimizMenusuAc(false)
            setYazilimHizmetMenusuAc(false)
            // return setHizmetMenusuAc(false)
        }
        if(loginMenusuAc === true){
            setLoginMenusuAc(false)
        }
        if(dilMenusuAc === true){
            setDilMenusuAc(false)
        }
        // setHizmetMenusuAc(true)
        setHizmetMenusuAc(!hizmetMenusuAc)
        // menulariOtomatikKapa()
    }

    const yazilimHizmetMenusuAcIsle = (e) => {
        e.stopPropagation()
        setYazilimHizmetMenusuAc(!yazilimHizmetMenusuAc)
        if(yazilimlarimizMenusuAc === true) {
            return setYazilimlarimizMenusuAc(false)
        }
        // setYazilimHizmetMenusuAc(true)
        // menulariOtomatikKapa()
    }

    const yazilimlarimizMenusuAcIsle = (e) => {
        e.stopPropagation()
        setYazilimlarimizMenusuAc(!yazilimlarimizMenusuAc)
        if(yazilimHizmetMenusuAc === true) {
            return setYazilimHizmetMenusuAc(false)
        }
        // setYazilimlarimizMenusuAc(true)
        // menulariOtomatikKapa()
    }

    const loginMenusuAcIsle = (e) => {
        e.stopPropagation()
        setLoginMenusuAc(!loginMenusuAc)
        if(hizmetMenusuAc === true) {
            setHizmetMenusuAc(false)
            setYazilimlarimizMenusuAc(false)
            setYazilimHizmetMenusuAc(false)
        }
        if(dilMenusuAc === true){
            setDilMenusuAc(false)
        }
        // menulariOtomatikKapa()
    }

    const dilMenusuAcIsle = (e) => {
        e.stopPropagation()
        setDilMenusuAc(!dilMenusuAc)
        if(hizmetMenusuAc === true) {
            setHizmetMenusuAc(false)
            setYazilimlarimizMenusuAc(false)
            setYazilimHizmetMenusuAc(false)
        }
        if(loginMenusuAc === true) {
            setLoginMenusuAc(false)
        }
        // menulariOtomatikKapa()
    }

    const butunMenuleriKapaIsle = (e) => {
        e.stopPropagation()
        if(hizmetMenusuAc === true) {
            setHizmetMenusuAc(false)
            setYazilimlarimizMenusuAc(false)
            setYazilimHizmetMenusuAc(false)
        }
        if(loginMenusuAc === true) {
            setLoginMenusuAc(false)
        }
        if(dilMenusuAc === true){
            setDilMenusuAc(false)
        }
    }

    const ortaKismiKontrolEt = () => {
        if ( genislik <720 ) {
            if(!logoGizlimi) {
                return 'navCenterSideCls invisibleCls'
            }
            else {
                return 'navCenterSideCls'
            }
        }
        else {
            return 'navCenterSideCls'
        }
    }
    
    const hakkindaMenusunuKontrolEt = () => {
        if ( genislik <720 ) {
            if(gosterilenMenu !== 'navAboutCls') {
                return 'navAboutCls invisibleCls'
            }
            else {
                return 'navAboutCls'
            }
        }
    }

    const hizmetlerimizMenusunuKontrolEt = () => {
        if ( genislik <720 ) {
            if(gosterilenMenu !== 'navOurServicesCls') {
                return 'navOurServicesCls invisibleCls'
            }
            else {
                return 'navOurServicesCls'
            }
        }
    }

    const baglantilarMenusunuKontrolEt = () => {
        if ( genislik <720 ) {
            if(gosterilenMenu !== 'navLinksCls') {
                return 'navLinksCls invisibleCls'
            }
            else {
                return 'navLinksCls'
            }
        }
    }

    const haberlerMenusunuKontrolEt = () => {
        if ( genislik <720 ) {
            if(gosterilenMenu !== 'navNewsCls') {
                return 'navNewsCls invisibleCls'
            }
            else {
                return 'navNewsCls'
            }
        }
    }

    const bizeUlasinMenusunuKontrolEt = () => {
        if ( genislik <720 ) {
            if(gosterilenMenu !== 'navContactUsCls') {
                return 'navContactUsCls invisibleCls'
            }
            else {
                return 'navContactUsCls'
            }
        }
    }

    const girisMenusunuKontrolEt = () => {
        if ( genislik <720 ) {
            if(gosterilenMenu !== 'navLoginCls') {
                return 'navLoginCls invisibleCls'
            }
            else {
                return 'navLoginCls'
            }
        }
    }
    const dilMenusunuKontrolEt = () => {
        if ( genislik <720 ) {
            if(gosterilenMenu !== 'navLanguageCls') {
                return 'navLanguageCls invisibleCls'
            }
            else {
                return 'navLanguageCls'
            }
        }
    }

    return (
        <nav 
        //ref={navBarRef}
        className='navBarCls'
        >
            <div 
            className={logoGizlimi ? 'navLeftSideCls invisibleCls' : 'navLeftSideCls'}
            id='navLeftSideID'
            >
                <AnimatedLink
                    aria-description={t('genericStrings:web_site_description')}
                    to={"/"}
                    className= 'logoCls'
                    onClick={butunMenuleriKapaIsle}
                    variants={throbVariant}
                    initial="initial"
                    animate="throb"
                >
                {t('genericStrings:web_site_name')}
                </AnimatedLink>
            </div>
            <div
            className={ortaKismiKontrolEt()}
            // className={genislik <720 ? 'navCenterSideCls invisibleCls' : (genislik <720 && gosterilenMenu === null) ? 'navCenterSideCls invisibleCls' : 'navCenterSideCls'  }
            // className={genislik <720 ? 'navCenterSideCls invisibleCls' : 'navCenterSideCls' }
            // className={(genislik <720 && gosterilenMenu === null) ? 'navCenterSideCls invisibleCls' : 'navCenterSideCls' }
            id='navCenterSideID'
            >
                <NavLink 
                aria-description={t('genericStrings:aboutUsDescription')}
                to={"/aboutus"}
                onClick={butunMenuleriKapaIsle}
                className={hakkindaMenusunuKontrolEt()}
                // className='navAboutCls'
                // className={(genislik <720 && gosterilenMenu === 'navAboutCls') ? 'navAboutCls' : 'navAboutCls invisibleCls' }
                >
                {t('regularMenus:aboutUsMenu')}
                </NavLink>
                <div 
                className='navBarDropDownServicesCls'
                id='navBarDropDownServicesID'
                >
                    <NavLink 
                    aria-description={t('genericStrings:ourServicesDescription')}
                    to={"#"} 
                    className={hizmetlerimizMenusunuKontrolEt()}
                    // className='navOurServicesCls'
                    // className= {gosterilenMenu === 'navOurServicesCls' ? 'navOurServicesCls' : 'navOurServicesCls invisibleCls' }
                    // className= {(genislik <720 && gosterilenMenu === 'navOurServicesCls') ? 'navOurServicesCls' : 'navOurServicesCls invisibleCls' }
                    onClick={hizmetMenusuAcIsle}
                    >
                    {t('regularMenus:ourServicesMenu')} {hizmetMenusuAc === false ? <FaAngleLeft/> : <FaAngleDown/> }
                    </NavLink>
                        <div 
                        >
                            <div>
                            <NavLink 
                            aria-description={t('genericStrings:translationServicesDescription')}
                            to={"translationservices"}
                            onClick={butunMenuleriKapaIsle}
                            className={hizmetMenusuAc === true ? '' : 'invisibleCls'}
                            >
                            {t('regularMenus:translationServicesMenu')} 
                            </NavLink>
                            </div>
                            <div
                            id='softwareMenuID'
                            >
                            <NavLink 
                            to={"#"}
                            aria-description={t('genericStrings:softwareDevelopmentServicesDescription')}
                            // ref={ourServicesRef} 
                            className={hizmetMenusuAc === true ? '' : 'invisibleCls'}
                            onClick={yazilimHizmetMenusuAcIsle}
                            >
                            {t('regularMenus:softwareDevelopmentServicesMenu')} {yazilimHizmetMenusuAc === false ? <FaAngleLeft/> : <FaAngleDown/> }
                            </NavLink>
                                <div>
                                    <NavLink 
                                    aria-description={t('genericStrings:webSiteDevelopmentServicesDescription')}
                                    to={"webdevelopment"}
                                    // ref={ourServicesRef} 
                                    className={yazilimHizmetMenusuAc === true ? 'altMenuCls' : 'invisibleCls'}
                                    >
                                    {t('regularMenus:websiteDevelopmentServicesMenu')}
                                    </NavLink>
                                </div>
                                <div>
                                    <NavLink
                                    aria-description={t('genericStrings:pcSoftwareDevelopmentServicesDescription')}
                                    to={"/customsoftware"}
                                    // ref={ourServicesRef}
                                    className={yazilimHizmetMenusuAc === true ? 'altMenuCls' : 'invisibleCls'}
                                    >
                                    {t('regularMenus:customPCDevelopmentServicesMenu')}
                                    </NavLink>
                                </div>
                                <div>
                                    <NavLink 
                                    aria-description={t('genericStrings:macOsSoftwareDevelopmentServicesDescription')}
                                    to={"customsoftware"}
                                    // ref={ourServicesRef}
                                    className={yazilimHizmetMenusuAc === true ? 'altMenuCls' : 'invisibleCls'}
                                    >
                                    {t('regularMenus:customMacOSDevelopmentServicesMenu')}
                                    </NavLink>
                                </div>
                                <div>
                                    <NavLink
                                    aria-description={t('genericStrings:androidSoftwareDevelopmentServicesDescription')}
                                    to={"customsoftware"}
                                    // ref={ourServicesRef}
                                    className={yazilimHizmetMenusuAc === true ? 'altMenuCls' : 'invisibleCls'}
                                    >
                                    {t('regularMenus:customAndroidDevelopmentServicesMenu')}
                                    </NavLink>
                                </div>
                                <div>
                                    <NavLink 
                                    aria-description={t('genericStrings:iOsSoftwareDevelopmentServicesDescription')}
                                    to={"customsoftware"}
                                    // ref={ourServicesRef}
                                    className={yazilimHizmetMenusuAc === true ? 'altMenuCls' : 'invisibleCls'}
                                    >
                                    {t('regularMenus:customIOSDevelopmentServicesMenu')}
                                    </NavLink>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <NavLink 
                                    aria-description={t('genericStrings:ourSoftwareProductsDescription')}
                                    to={"#"} 
                                    // ref={ourServicesRef} 
                                    onClick={yazilimlarimizMenusuAcIsle}
                                    className={hizmetMenusuAc === true ? '' : 'invisibleCls'}
                                    >
                                    {t('regularMenus:ourSoftwareProductsMenu')} {yazilimlarimizMenusuAc === false ? <FaAngleLeft/> : <FaAngleDown/> }
                                    </NavLink>
                                    <div>
                                        <NavLink 
                                        aria-description={t('genericStrings:TOMSDescription')}
                                        to={"https://toms.atb-soft.com"} 
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        // ref={ourServicesRef} 
                                        className={yazilimlarimizMenusuAc === true ? 'altMenuCls' : 'invisibleCls'}
                                        >
                                        {t('regularMenus:TOMSMenu')}
                                        </NavLink>
                                    </div>
                                    <div>
                                        <NavLink 
                                        aria-description={t('genericStrings:TDSDictionaryAndroidDescription')}
                                        to={"https://play.google.com/store/apps/details?id=my.translation.dictionary"} 
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        // ref={ourServicesRef} 
                                        className={yazilimlarimizMenusuAc === true ? 'altMenuCls' : 'invisibleCls'}
                                        >
                                        {t('regularMenus:cevirdicBizAndroidMenu')}
                                        </NavLink>
                                    </div>
                                    <div>
                                        <NavLink
                                        aria-description={t('genericStrings:TDSDictionaryiOsDescription')}
                                        to={"https://apps.apple.com/app/cevirdic-biz/id1642503577"} 
                                        // ref={ourServicesRef} 
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className={yazilimlarimizMenusuAc === true ? 'altMenuCls' : 'invisibleCls'}
                                        >
                                        {t('regularMenus:cevirdicBiziOsMenu')}
                                        </NavLink>
                                    </div>
                                    <div>
                                        <NavLink 
                                        aria-description={t('genericStrings:hangAWordAndroidDescription')}
                                        to={"https://play.google.com/store/apps/details?id=my.translation.hangaword"} 
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        // ref={ourServicesRef} 
                                        className={yazilimlarimizMenusuAc === true ? 'altMenuCls' : 'invisibleCls'}
                                        >
                                        {t('regularMenus:hangAWordAndroidMenu')}
                                        </NavLink>
                                    </div>
                                    <div>
                                        <NavLink
                                        aria-description={t('genericStrings:hangAWordiOsDescription')}
                                        to={"https://apps.apple.com/us/app/hangaword/id6475181526"} 
                                        // ref={ourServicesRef} 
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className={yazilimlarimizMenusuAc === true ? 'altMenuCls' : 'invisibleCls'}
                                        >
                                        {t('regularMenus:hangAWordiOsMenu')}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div>
                            <NavLink 
                            aria-description={t('genericStrings:foreignConsultancyDescription')}
                            to={"#"}
                            // ref={ourServicesRef}
                            className={hizmetMenusuAc === true ? '' : 'invisibleCls'}
                            >
                            {t('regularMenus:foreignConsultancyMenu')}
                            </NavLink>
                            </div>
                        </div>
                </div>
                <NavLink 
                aria-description={t('genericStrings:linksDescription')}
                to={"/links"} 
                // ref={linksRef} 
                onClick={butunMenuleriKapaIsle}
                className={baglantilarMenusunuKontrolEt()}
                // className='navLinksCls'
                // className= {(genislik <720 && gosterilenMenu === 'navLinksCls') ? 'navLinksCls' : 'navLinksCls invisibleCls' } 
                >
                {t('regularMenus:linksMenu')}
                </NavLink>
                <NavLink 
                aria-description={t('genericStrings:newsDescriptionForPage')}
                to={"/news"} 
                onClick={butunMenuleriKapaIsle}
                className={haberlerMenusunuKontrolEt()}
                // className='navNewsCls'
                // className={(genislik <720 && gosterilenMenu === 'navNewsCls') ? 'navNewsCls' : 'navNewsCls invisibleCls' } 
                >
                {t('regularMenus:newsMenu')}
                </NavLink>
                <NavLink 
                to={"/contactus"} 
                // ref={contactUsRef}
                onClick={butunMenuleriKapaIsle}
                className={bizeUlasinMenusunuKontrolEt()}
                // className='navContactUsCls'
                // className={(genislik <720 && gosterilenMenu === 'navContactUsCls') ? 'navContactUsCls' : 'navContactUsCls invisibleCls' } 
                >
                {t('regularMenus:contactUsMenu')}
                </NavLink>
            </div>
            <div
            // {genislik >= 720 && <div
            // ref={navLoginRef} 
            className={girisMenusunuKontrolEt()}
            // className={genislik <720 ? 'navLoginCls invisibleCls': 'navLoginCls'}
            id='navLoginID'
            // onMouseOver={mouseOveriIsleLoginMenu}
            // onMouseLeave={digerMenuSifirla}
            >
                <div 
                // ref={navLoginDropDownRef} 
                className='navBarDropDownCls'>
                        <NavLink 
                        onClick={loginMenusuAcIsle}
                        to={"#"}
                        >{t('regularMenus:loginRegisterMenu')}{loginMenusuAc === false ? <FaAngleLeft/> : <FaAngleDown/> }
                        </NavLink>
                        <div 
                        // ref={loginDropDownUlRef}
                        >
                            <div 
                            className={loginMenusuAc === true ? 'navLoginCls' : 'invisibleCls'}
                            >
                            <NavLink to={'signin'}>{t('regularMenus:loginMenu')}</NavLink>
                            </div>
                            <div 
                            className={loginMenusuAc === true ? 'navLoginCls' : 'invisibleCls'}
                            >
                            <NavLink to={'signup'}>{t('regularMenus:registerMenu')}</NavLink>
                            </div>
                        </div>
                </div> 
            </div>
            {/* </div> } */}
            <div 
            // {genislik >= 720 && <div 
            // ref={navLanguageRef} 
            className={dilMenusunuKontrolEt() }
            // className={genislik <720 ? 'navLanguageCls invisibleCls' : 'navLanguageCls' }
            id='navLanguageID'
            // onMouseOver={mouseOveriIsleDilMenu}
            // onMouseLeave={digerMenuSifirla}
            >
                <div 
                className='navBarDropDownCls'
                >
                    <NavLink 
                    onClick={dilMenusuAcIsle}
                    // onTouchStart={genislik < 720 && showLanguageDropDown}
                    to={"#"}
                    >
                    {t('linguisticallyReverseMenuItems:guilangSelectS')}{dilMenusuAc === false ? <FaAngleLeft/> : <FaAngleDown/> }
                    </NavLink>
                    <div 
                    // ref={languageDropDownUlRef}
                    >
                        <div 
                        className={dilMenusuAc === true ? 'navLoginCls' : 'invisibleCls'}
                        >
                        <NavLink 
                        to={"#"}
                        onClick={() => {diliSimdiDegistir('tr')}}
                        >
                        {t('linguisticallyReverseMenuItems:guilangTurS')}
                        </NavLink>
                        </div>
                        <div 
                        className={dilMenusuAc === true ? 'navLoginCls' : 'invisibleCls'}
                        >
                        <NavLink 
                        to={"#"}
                        onClick={() => {diliSimdiDegistir('en')}}
                        >
                        {t('linguisticallyReverseMenuItems:guilangEngS')}
                        </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> } */}
            {genislik < 720 &&
            <div
                className={genislik >720 ? 'navLanguageCls nav-btn invisibleCls' : 'navLanguageCls nav-btn visibleCls' }
            >
                <button 
                className='langButtonCls'
                onClick={diliSimdiDegistirBayrak}>
                <Bayrak 
                bayrak={t('genericStrings:dilBayragi')}
                />
                </button>
                <motion.button
                    className='nav-btn'
                    onClick={menuleriSiraylaTekTekAcIsle}
                    variants={shakeVariant}
                    initial="initial"
                    animate="shake"
                    >
                    <CiMenuBurger
                    size={30}
                />
                </motion.button>
            </div>
            }
        </nav>
    )
};

export default NavBar
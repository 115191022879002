import React from 'react'
import { useTranslation } from 'react-i18next'
import Etiketler from '../Bilesenler/Etiketler'
import GomuluYoutubeVideosu from '../Bilesenler/GomuluYoutubeVideosu'

const Haberler =() =>{
    const { t } = useTranslation()
    const haberListesi = [
        {
            id: 1,
            baslik: t('genericStrings:newsTitle'),
            tanim: t('genericStrings:newsDescriptionForPage'),
            haberBasligi: t('genericStrings:newsCaption'),
            body: t('genericStrings:newsBody'),
            tags: t('genericStrings:newsTags', { returnObjects: true })
        }
    ]
    return (
        <div className='baglantilarCls'>
            <h1 aria-description={haberListesi[0].tanim}>{haberListesi[0].haberBasligi}</h1>
            <GomuluYoutubeVideosu 
                videoUri={'-druj8zDg90'} 
                videoBasligi={haberListesi[0].haberBasligi}
                videoGenisligi={560}
                videoYuksekligi={335}
            />
            <p aria-description={haberListesi[0].body}>{haberListesi[0].body}</p>
            <Etiketler gelenTaglar={haberListesi[0].tags} eklenecekTanim={haberListesi[0].tanim} />
        </div>
    )
}

export default Haberler
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { throbVariant } from '../Bilesenler/NavBar'
import TelefonVeWhatsApp from './TelefonVeWhatsApp'

const SirketKunyesi = () =>{
    const [telefonDiyaloguGoster, setTelefonDiyalogu] = useState(false)
    const { t } = useTranslation()
    const AnimatedLink = motion(Link);
    return (
        <div>
            <h2
            aria-description={t('genericStrings:companySignature')}
            className='profileHeaderOneCls'
            >
            {t('genericStrings:companySignature')}
            </h2>
            <AnimatedLink 
                id='callUsID'
                // to={"tel:"+t('genericStrings:companyContactInfo:whatsAppNo')}
                onClick={() => {setTelefonDiyalogu(!telefonDiyaloguGoster)}}
                // target='_blank'
                // rel='noopener noreferrer'
                aria-description={t('genericStrings:companyContactInfo:callUs')}
                className='boldGreenSpan inlineBlockCls'
                variants={throbVariant}
                initial="initial"
                animate="throb"
            >
                {t('genericStrings:companyContactInfo:callUs')}
                {t('genericStrings:companyContactInfo:whatsAppNo')}
            </AnimatedLink>
            <br />
            <AnimatedLink 
                id='getDirectionsID'
                to={`geo: ${t('genericStrings:companyContactInfo:lat')},${t('genericStrings:companyContactInfo:long')}`}
                target='_blank'
                rel='noopener noreferrer'
                aria-description={t('genericStrings:companyContactInfo:callUs')}
                className='boldGreenSpan inlineBlockCls'
                variants={throbVariant}
                initial="initial"
                animate="throb"
            >
                {t('genericStrings:companyContactInfo:getDirections')}
            </AnimatedLink>
            <br />
            <Link 
                to={t('genericStrings:companyContactInfo:link')}
                aria-description={t('genericStrings:companyContactInfo:email')}
            >
                {t('genericStrings:companyContactInfo:email')}
            </Link>
            <address>
                <p
                className='profileParagraphCls'
                >
                {t('genericStrings:companyContactInfo:addressS')} {t('genericStrings:companyContactInfo:companyAddress')} <br />
                <Link
                to={"tel:"+t('genericStrings:companyContactInfo:phone')}
                target='_blank'
                rel='noopener noreferrer'
                >
                {t('genericStrings:companyContactInfo:companyLandLine')} {t('genericStrings:companyContactInfo:phone')} 
                </Link>
                </p>
            </address>
            {telefonDiyaloguGoster && <TelefonVeWhatsApp setTelefonDiyalogu={setTelefonDiyalogu}/>}
        </div>
    )
}

export default SirketKunyesi